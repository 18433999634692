import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'users',
    name: ROUTES.ADMIN.ALL_USERS_LIST,
    meta: {breadcrumb: 'breadcrumbs.Users', activeKey: 'admin.admin_accounts'},
    component: () => import("@components/admin/users/ListAdminAccount"),
  },
];

<template>
  <div class="icheck-primary d-inline">
    <input
      type="checkbox"
      :name="name"
      :checked="isChecked"
      :value="val"
      :id="id ? id : uuid"
      :disabled="disabled"
      class="mr-2"
      @input="handleInput($event)"
    />
    <label class="control-label input-label" :for="id ? id : uuid">{{ label }}</label>
  </div>
</template>

<script>
  import {v4} from "uuid";

  export default {
    name: "AppCheckbox",

    props: {
      id: {
        type: String,
      },

      name: {
        type: String,
      },

      value: {},

      val: {
        type: [Boolean, Number, String],
        default: true,
      },

      label: {
        type: String,
      },

      labelRight: {
        type: Boolean,
        default: false,
      },

      disabled: {
        type: Boolean,
      },
      // isChecked: {
      //   type: Boolean,
      // }
    },

    data() {
      return {
        uuid: v4(),
      };
    },

    computed: {
      isChecked() {
        if (this.value && typeof this.value == "object") {
          let index = _.findIndex(this.value, (x) => x == this.val);
          return index === -1 ? false : true;
        } else {
          return Boolean(this.value) == Boolean(this.val);
        }
      },
    },

    methods: {
      handleInput(e) {
        if (this.value && typeof this.value == "object") {
          if (e.target.checked == true) {
            this.value.push(this.val);
          } else {
            let index = this.value.indexOf(this.val);
            if (index > -1) {
              this.value.splice(index, 1);
            }
          }
          this.$emit('input', this.value)
        } else {
          this.$emit('input', e.target.checked)
        }
      },
    },
  };
</script>

import API from '@consts/api';
import AuthConfig from '@consts/auth'

export default {
  install(Vue, options) {
    const app = new Vue;
    Vue.prototype.$auth = {
      login: async function (data) {
        const response = await Vue.prototype.$request.post(API.AUTH.LOGIN, data)
        if (response?.headers?.authorization) {
          app.$store.commit(`auth/updateAuth`, {
              user: response.data.data,
              token: response.headers.authorization,
              expire_time: response.headers['token-expire-time']
            }
          )
        }
        return response;
      },

      logout: async function () {
        let role = app.$store.state.auth.currentAuth.role;
        await Vue.prototype.$request.delete(API.AUTH.LOGOUT)
        app.$store.commit(`auth/removeAuth`)
        Vue.router.pushByName(AuthConfig[role].routes.login)
      },

      user: function () {
        return app.$store.getters["auth/getUser"]
      },
    }
  }
}

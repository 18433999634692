import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}
export default [
  {
    path: 'bookings',
    name: ROUTES.ADMIN.BOOKINGS,
    meta: {
      breadcrumb: i18n.tc('breadcrumbs.Booking'),
      activeKey: 'admin.bookings'
    },
    component: routerView,
    children: [
      {
        path: '',
        name: ROUTES.ADMIN.BOOKINGS,
        meta: {
          activeKey: 'admin.bookings'
        },
        component: () => import("@components/admin/bookings/BookingListPage"),
      },
      {
        path: 'create',
        name: ROUTES.ADMIN.BOOKING_CREATE,
        meta: {breadcrumb: 'breadcrumbs.Create booking', activeKey: 'admin.bookings'},
        component: () => import("@components/admin/bookings/BookingCreateOrEdit"),
      },
      {
        path: ':id/edit',
        name: ROUTES.ADMIN.BOOKING_UPDATE,
        meta: {breadcrumb: 'breadcrumbs.Edit booking', activeKey: 'admin.bookings'},
        component: () => import("@components/admin/bookings/BookingCreateOrEdit"),
      },
      {
        path: ':id/edit-tour',
        name: ROUTES.ADMIN.BOOKING_UPDATE_TOUR,
        meta: {breadcrumb: 'breadcrumbs.Edit tour', activeKey: 'admin.bookings'},
        component: () => import("@components/admin/bookings/tour_order/Update"),
      }
    ]
  }
];

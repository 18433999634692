export default {
  "common": {
    "password": "パスワード",
    "password confirm": "パスワード再入力",
    "email": "Eメール",
    "mail address": "メールアドレス",
    "language": "言語",
    "next": "次へ",
    "pre": "前へ",
    "name": "名前",
    "role": "ロール",
    "action": "編集",
    "status": "ステータス",
    "active": "有効",
    "deactive": "無効",
    "total": "合計",
    "create": "新規登録",
    "reset": "リセット",
    "perpage": "表示件数",
    "cancel": "キャンセルする",
    "confirm": "はい",
    "no": "いいえ",
    "search": "検索",
    "phone": "電話番号",
    "creator": "作成者",
    "created at": "作成日",
    "updated at": "更新日",
    "address": "住所",
    "fax": "FAX",
    "save": "登録する",
    "create_btn": "登録する",
    "update_btn": "保存する",
    "back": "戻る",
    "send": "送信",
    "error": "エラーが発生しました。再度実行してください。",
    "new_password": "新しいパスワード",
    "new_password_confirm": "新しいパスワード（確認）",
    "current_password": "現在のパスワード",
    "current_email": "現在のメールアドレス",
    "new_email": "新メールアドレス",
    "msg update ok": "登録内容の変更が正常に完了しました。",
    "msg public product all success": "商品の括公開が完了しました。",
    "msg draft product all success": "商品の下書きが完了しました。",
    "msg delete product all success": "商品の括削除が完了しました。",
    "msg public coupon all success": "チケットの括公開が完了しました。",
    "msg draft coupon all success": "チケットの下書きが完了しました。",
    "msg delete coupon all success": "チケットの括削除が完了しました。",
    "msg create ok": "登録が正常に完了しました。",
    "msg delete ok": "正常に削除しました。",
    "msg inactive ok": "アカウントが正常に無効化されました。",
    "msg active ok": "アカウントのアクティブ化に成功。",
    "delete confirm": "本当に削除しますか？",
    "delete confirm title": "本当に削除しますか？",
    "view": "見る",
    "edit": "編集",
    "delete": "削除",
    "information of": "{entry}の詳細情報",
    "loading": "表示件数。。。",
    "list empty": "データがありません。",
    "start_date": "開始日",
    "end_date": "終了日",
    "price": "料金",
    "price_plan": "価格",
    "upload submit": "アップロード",
    "choose file": "ファイル選択",
    "file ext err": "ファイルタイプが正しくありません。csvタイプのファイルをアップロードしてください。",
    "import success": "ファイルのアップロードが成功しました。",
    "import err": "ファイルのアップロードが失敗しました。",
    "no_select": '該当するデータが見つかりませんでした。',
    "yes": "Yes",
    "no data found": "No data found!",
    "download csv": "CSVダウンロード",
    "select_all": "すべて",
    "no_select_all": "未選択",
    'people_count_icon': '名',
    'yen_icon': '円',
    'change_notice': 'データが変更されています。本当に閉じますか。',
    'notice_modal_title': '通知',
    "change_pass": "パスワード編集",
    '#': 'No.',
    'confirm_cancel_title': '現在入力している情報は保存されません。本当にキャンセルしますか？',
    'active_to_inactive': "本当にアカウントを無効化しますか？",
    'inactive_to_active': "本当にアカウントを有効化しますか？",
    'completed' : "完了する",
    'draft' : "対応中に戻す",
    'public_btn': "公開する",
    'draft_btn': "下書き保存",
    "public_or_non_public": "公開／非公開",
    'confirm_publish_all_product': "選択した商品を公開します。続行してよろしいですか？",
    'confirm_draft_all_product': "選択した商品を下書きに戻します。続行してよろしいですか？",
    'confirm_delete_all_product': "選択した商品を公開します。 続行してよろしいですか？",
    'confirm_delete_product_draft': "商品を削除してよろしいですか？",
    'confirm_delete_product_public': "公開中の商品を削除しようとしています。\n" +
      "本当に削除してよろしいですか？",
    'confirm_publish_all_coupon': "選択したクーポンを公開します。続行してよろしいですか？",
    'confirm_draft_all_coupon': "選択したクーポンを非公開にします。続行してよろしいですか？",
    'confirm_delete_all_coupon': "選択したクーポンを一括削除します。続行してよろしいですか？",
    'confirm_delete_coupon': "クーポンを削除してよろしいですか？",
  },
  "auth": {
    "login": "ログインする",
    "login page": "ログイン",
    "forgot password page": "パスワードをリセットする ",
    "forgot password note": "メールでパスワードリセット用のURLを送信します。",
    "verify ok": "登録していただいたメールアドレスが確認されました。",
    "reset pass ok": "パスワードが正常に再設定されました。",
    "reset password page": "パスワードリセット ",
    "change password": "パスワード変更",
    "profile": "プロファイル",
    "reset password": "再設定",
    "forgot_password_label": "パスワードをお忘れの方はこちら",
    'password note 1': "※パスワードは8文字以上~20文字以下半角英数字で、大文字、小文字、数字を全て含んでいる必要があります。",
    'password note 2': "※Please include at least one letter, uppercase letters, and lowercase letters."
  },
  "dialog": {
    "confirm title": "Confirmation Dialog",
    "confirm body": "Are you sure do this action?",
    "image_type": "正しい形式ではありません。jpegもしくはpngでご登録ください。",
    "image_max": "アップロードできるファイルは最大{size}までとなります。",
    "not_email": "メールではありません",
    "not_phone": "電話番号ではありません",
    "not_image": "こちらは必須項目です。",
    "not_link": "リンクではありません",
    "star_end_work": "営業終了時間には営業開始時間以降の時間を指定してください。",
  },
  "nav": {
    "dashboard": "ダッシュボード",
    "logout": "ログアウト",
    "user list": "ユーザー一覧",
    "admin account": "Adminアカウント管理",
    "members": "会員管理",
    "profile": "プロファイル",
    "users setting": "アカウント管理",
    "tour_orders": "ツアーオーダー設定",
    "offices": "事業所管理",
    "office_plans": "プラン管理",
    "admin_accounts": "管理者アカウント管理",
    "bookings": "HOME（進行管理）",
    "change_pass": "パスワード",
    "furusato_product_list": "Webカタログ商品一覧",
    "furusato_product_create": "Webカタログ商品登録",
    "furusato_product": "Webカタログ商品登録",
    "furusato_coupon_list": "ツアーチケット一覧",
    "furusato_coupon_create": "ツアーチケット登録",
    "furusato_coupon_use": "ツアーチケット利用履歴",
  },
  "breadcrumbs": {
    "Home": "ダッシュボード",
    "Dashboard": "ダッシュボード",
    "Users management": "Users",
    "Create user": "Create user",
    "Admin users": "Adminアカウント管理",
    "Profile": "プロファイル",
    "Users": "管理者アカウント管理",
    "offices": "事業所管理",
    "office_plans": "プラン管理",
    "Tour_orders": "ツアーオーダー設定",
    "Tour_orders add": "ツアーオーダー登録画面",
    "Tour_orders update": "ツアーオーダー編集画面",
    "admin_accounts": "管理者アカウント管理",
    "members": "会員管理",
    "Booking": "HOME(進行管理)",
    "Create booking": "登録する",
    "Edit booking": "保存する",
    "Edit tour": '企画書編集',
    "Furusato_product": "Webカタログ商品一覧",
    "Furusato_product add": "Webカタログ商品登録",
    "Furusato_product update": "編集",
    "Furusato_coupon": "ツアーチケット一覧",
    "Furusato_coupon add": "新規登録",
    "Furusato_coupon update": "編集",
    "Furusato_coupon use": "ツアーチケット利用履歴",
  },
  "users": {
    "name": "アカウント名",
    "admin user list": "Adminアカウント管理",
    "create user": "アカウント登録",
    "update user": "アカウント編集",
    "resent_active_email": 'Resent Active URL',
    "sent_email_msg": "Sent Email Successful.",
    "role": "Authority",
    "admin": "Admin",
    "user": "User",
    "created_at": "登録日時",
    "last_login": "最終ログイン日時",
    "active": "ステータス",
    "active_on": "利用中",
    "active_off": "停止中",
    "keyword": "キーワードで探す",
    "email": "メールアドレス",
    "phone": "電話番号",
    "old": "現在のパスワード",
    "new": "新しいパスワード",
    "new_confirm": "パスワード（確認)",
    "edit_users": "変更する",
    "update_profile": "プロフィール設定",
  },
  "members": {
    "user_type": "会員属性",
    "members_0": "一般",
    "members_1": "ふるさと納税",
    "name": "顧客代表者名",
    "Furigana": "フリガナ",
    "gender": "性別",
    "gender_0": "男性",
    "gender_1": "女性",
    "birthday": "生年月日",
    "post_code": "郵便番号",
    "postal_code": "郵便番号",
    "address": "住所",
    "created_at": "登録日時",
    "last_login": "最終ログイン日時",
    "members_basic": "会員様基本情報",
    "detail": "お名前",
    "tanaka": "田中",
    "taro": "太郎",
    "furigana": "フリガナ",
    "tanaca": "タナカ",
    "taro_2": "タロウ",
    "prefecture": "都道府県",
    "cities": "市区町村",
    "street": "町名番地",
    "building": "物件名・部屋番号",
    "introducer_name": "顧客紹介者",
    "history_booking": "過去に参加したツアー",
    "loadmore": "もっと見る",
    "datetime": "対応日時",
    "admin_name": "KGT対応者",
    "content": "対応メモ",
    "export_csv": "CSV ダウンロード",
    "history": "対応履歴",
    "cancel": "キャンセルする",
    "format": "旅程日:",
  },
  "pages": {
    "404_msg": "アクセスいただいたURLが見つかりません。"
  },
  "tour_order_template": {
    "name": "ツアー名",
    "tourname_name": "ツアー名設定",
    "tourname_name_name": "ツアー名",
    "tourname_name_explain": "ツアー説明",
    "tourname_image": "画像（ファイルのサイズは５MBまで設定してください。）",
    "tourname_concept": "コンセプト設定",
    "schedule_templates": "スケジュール設定",
    "budgets": "ツアー予算設定",
    "budgets_name": "内容",
    "budgets_quantity": "参加人数",
    "budgets_price": "金額・税込",
    "note": "注意事項設定",
    "contact": "お問い合わせ窓口設定",
    "contact_content": "お問い合わせ窓口",
    "contact_image": "画像（5MBの画像をアップロードしてください。）",
    "edit_history": "変更履歴（コンシェルジュ用メモ）",
    "schedule_day": "DAY",
    "schedule_date": "日付",
    "schedule_des": "説明",
    "schedule_title": "手配内容",
    "schedule_time": "タイムスケジュール",
    "offices_name": "事業所名",
    "office_plans": "プラン選択",
    "offices_explain": "事業所 / プラン説明",
    "offices_image": "事業所 / プラン画像",
    "offices_map_url": "事業所住所",
    "offices_tel": "電話番号",
    "area_label": "エリア",
    "public_url": "ツアーのURL",
    "area_prefecture": "都道府県",
    "area_city": "市区町村",
    "schedule_day ": "市区町村",
    "description": "ツアー説明",
    "budgets_total": "お支払い金額",
    "price": "ツアー金額",
    "price_from": "価格から",
    "price_to": "価格に",
    'reserved_content': '手配内容',
    'is_draft_reserved': '仮予約の手配',
    'is_final_reserved': '本予約の手配',
    'tour_type': 'ツアータイプ',
    'normal_type': '一般',
    'furusato_type': 'ふるさと納税',
    'furusato_tour_city': '自治体名',
    'furusato_code': '商品番号',
    'furusato_from_site': '掲載サイト',
    'extra_info': 'Extra Info',
  },
  "offices": {
    "company_name": "企業名",
    "office_name": "事業所名",
    "homepage_url": "事業所ホームページ",
    "offices_map_url": "事業所住所",
    "offices_tel": "電話番号",
    "manager_email": "担当者メールアドレス",
    "manager_email_tb": "担当者<line>メールアドレス",
    "start_work": "営業開始時間",
    "end_work": "営業終了時間",
    "offices_des": "事業所説明",
    "offices_img": "事業所の画像",
    "offices_img_tb": "事業所の<line>画像",
    "status_office": "利用ステータス",
    "status_active": "利用中",
    "status_off": "停止中",
    "category_id": "事業所のカテゴリー",
    "text_hotel": "ホテル",
    "text_shop": "飲食店",
    "text_activity": "アクティビティ",
    "text_other": "その他",
    "create_offices": "登録する",
    "create_office_plans": "プランを登録する",
    "delete_office_plans": "プランを削除してよろしいですか?",
    "edit_offices": "保存する",
    "text_cate": "事業所のカテゴリー",
    "text_cate_tb": "事業所の<line>カテゴリー",
    "status": "利用ステータス",
    "status_tb": "利用<line>ステータス",
    "no_select_time": "時間を選択してください",
    "full_city_id": "自治体名",
    "plans": "プラン一覧",
    "plan_name": "プラン名",
    "status_plan": "販売ステータス",
    "display_status": "ステータス表示",
    "sale": "販売中",
    "stop_sale": "停止中",
    "plan_description": "プラン説明",
    "plan_image": "プラン画像"
  },
  "booking": {
    'id': '手配No.',
    'member_name': '顧客代表者名',
    'discuss_method': 'ご相談方法',
    'discuss_time': '相談希望日時',
    'hearing_first_time': '初回対応日',
    'admin_name': 'KGT担当',
    'admin_name_select': 'KGT担当',
    'hearing_tour_purpose': '旅行目的',
    'user_type': '属性',
    'hearing_area': 'エリア',
    'hearing_budget': '旅行予算',
    'start_date': '旅行開始日',
    'end_date': '旅行終了日',
    'adult_count': '人数大人',
    'child_count': '人数子供',
    'hearing_room_allocation': '宿 / 部屋割り',
    'hearing_meal_note': '食事/アレルギー対',
    'hearing_tour_note': '体験サービスなどの希望',
    'hearing_other_note': 'その他特別な要望',
    'furusato_nozei_product_note': 'ふるさと納税申し込み商品',
    'tour_order_name': 'ツアー名',
    'application_deadline': '申込期限',
    'proposal_status': '企画書・見積書',
    'invoice_status': '請求書',
    'tour_order_price': '入金予定額',
    'payment_deadline': '入金期限',
    'payment_status': '入金ステータス',
    'hearing_plan_time': '日程（予定）',
    'status': '対応ステータス',
    'booking_date': '旅行日程',
    'introduce_info': '紹介者',
    'people_num': '人数',
    'people_num_tour': '人数（予定）  ',
    'tour_name': 'ツアー名',
    'edit_tour': '企画書編集',
    'tour_price': '入金',
    'member_email': 'メールアドレス',
    'member_phone': '電話番号',
    'update_batch_payment': '入金ステータス変更',
    'export_csv': 'CSVダウンロード',
    'payment_date': '入金日時',
    'payment_confirm_admin_id': '確認者',
    'select_tour_btn': '選択する',
    'choose_tour': '選択する',
    'schedule_history': '手配項目',
    'schedule_history_detail': '詳細',
    'modal_confirm_update_title': 'ツアーを本当に変更しますか？',
    'modal_confirm_update_body': '元に戻れませんので、ご注意ください。',
    's_history_at': '対応日時',
    'reserved_content': '手配内容',
    'is_draft_reserved': '仮予約の手配',
    'is_final_reserved': '本予約の手配',
    'schedule_date': '日付',
    'setting_tour': '保存する',
    'update_payment': '変更する',
    "prefecture": "都道府県",
    "cities": "市区町村",
    '1_title_first_support' : '01. コンシェルジュデスク初回対応',
    'discuss_method_label' : "相談方法",
    'tour_note' : "メモ",
    '2_input_heading_sheet' : "02. ヒアリングシート",
    'area_label': "確認者",
    'room_location': "宿/部屋割り",
    'food_diet' : "食事 / アレルギー対応",
    'tour_experience_service' : "体験サービスなどの",
    'other_special' : "その他特別な希望",
    '3_travel_planing' : "03. 旅行企画書作成／仮予約・進行管理",
    'customer_name' : "顧客代表者名",
    'customer_name_select' : "選択してください。",
    'amount' : "金額",
    'status_check' : "承認状況",
    'recognize' : "承認",
    'planing_document_create' : "企画書作成",
    'recognizer' : "承認者",
    'recognition_date' : "承認日時",
    'note' : "備考",
    'proposal_approval' : "企画書承認",
    'temporary_reservation' : "仮予約",
    'book_reservation' : "本予約",
    'completed' : "完了する",
    'please_choose' : "対応ステータス",
    '4_final_information' : "04. 最終案内",
    'hope_to_mail' : "郵送希望",
    'add_information' : "補足情報",
    'introduction_facilities' : "滞在施設ご紹介",
    'extra_info' : "説明",
    'extra_image' : '滞在施設の画像',
    'upload' : 'アップロード',
    'schedule_day_explan' : '当日の流れと施設情報',
    'time' : "時間",
    'location' : "場所",
    'itinerary' : "場所",
    'route' : '行程説明',
    'nearby_information' : "",
    'nearby_information_title' : "周辺情報",
    'basic_information' : "基本情報",
    'attention_item' : "注意項目",
    'pay_locally' : "現地でお支払い",
    'content_paid' : "",
    'payment_destination' : "支払先",
    'detail' : "詳細",
    'adult' : "大人",
    'child' : "子供",
    'tour_overview' : 'ツアー概要',
    'preview': 'プレビュー',
    'to_add' : "追加する",
    'budget_remark_status' : '備考表示',
    'budget_remark' : '備考',
    'budget_remark_default' : '＊交通を除いた料金となります。フライト・レンタカーはお客様にて御手配をお願い致します。ランチ代はその場での清算となります。',
  },
  "furusato_product": {
    "master_code": "マスタ番号",
    "furusato_city_id": "自治体名",
    "furusato_office_id": "事業所名",
    "name": "返礼品名",
    "category": "商品カテゴリー",
    "category_sleep": "宿泊",
    "category_food": "飲食",
    "category_experience": "体験",
    "price": "価格",
    "stocks": "商品在庫数",
    "attendee": "参加可能人数",
    "limit_of_attendee_adults": "大人",
    "limit_of_attendee_children": "子供",
    "description": "商品内容",
    "furusato_image": "商品画像アップロード",
    "reception_date": "受付開始日・終了日",
    "start_date": "開始日",
    "end_date": "終了日",
    "category_list": "カテゴリー",
    "created_at": "登録日",
    "publish_all": "一括公開",
    "draft_all": "一括下書きに戻す",
    "delete_all": "一括削除",
    "public": "公開中",
    "draft": "下書き",
    "btn_draff": "下書きに戻す",
    "message_error_no_product_select": "商品が選択されていません。",
    "all_product_tab": "全ての商品",
    "all_public_product_tab": "WEBカタログに公開中",
    "all_draft_product_tab": "下書き",
    "text_to_draft_button": "下書きに戻す",
    "text_to_public_button": "公開する",
    "edit" : "Webカタログ商品編集"
  },
  "furusato_coupon": {
    "coupon_code": "チケット番号",
    "type": "チケットの種類",
    "type_coupon": "クーポン",
    "type_tour_ticket": "ツアーチケット",
    "discount_price": "割引金額",
    "product": "商品選択",
    "name_coupon": "チケット名",
    "publish_num": "発行数",
    "reception_date": "有効期限",
    "start_date": "開始",
    "end_date": "終了",
    "code_coupon": "ツアーチケットマスタ番号",
    "coupon_name": "ツアーチケット名",
    "public": "公開",
    "non_public": "非公開",
    "change_status_to_public": "公開にする",
    "change_status_to_non_public": "非公開にする",
    "non_publish_all_btn": "一括非公開",
    "price_discount": "割引価格",
    "status_use_coupon": "クーポン利用状況",
    "message_error_upload_max_file": "画像ファイルを1-10枚まで選択してください。",
    "message_error_no_coupon_select": "クーポンが選択されていません。",
    "edit" : "ツアーチケット編集",
  },
  "furusato_coupon_use": {
    "name": "観客名",
    "email": "メールアドレス",
    "ticket_tour": "クーポン番号",
    "booking_id": "手配No",
    "booking_id_table": "対象手配No",
    "date_use": "ご利用日",
    "ticket_tour_header": "ツアーチケット番号",
    "add_coupon_header": "登録日",
    "valid_time_header": "有効期限"
  }
}

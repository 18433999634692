import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}
export default [
  {
    path: '',
    name: ROUTES.ADMIN.BOOKINGS,
    meta: {
      breadcrumb: i18n.tc('breadcrumbs.Booking'),
      activeKey: 'admin.bookings'
    },
    component: routerView,
    children: [
      {
        path: 'furusato-coupon',
        name: ROUTES.ADMIN.FURUSATO_COUPON,
        meta: {breadcrumb: 'breadcrumbs.Furusato_coupon', activeKey: 'admin.furusato_coupon_list'},
        component: routerView,
        children: [
          {
            path: '',
            name: ROUTES.ADMIN.FURUSATO_COUPON,
            meta: {activeKey: 'admin.furusato_coupon_list'},
            component: () => import("@components/admin/furusato_coupon/Index"),
          },
          {
            path: 'create',
            name: ROUTES.ADMIN.FURUSATO_COUPON_CREATE,
            meta: {breadcrumb: 'breadcrumbs.Furusato_coupon add', activeKey: 'admin.furusato_coupon'},
            component: () => import("@components/admin/furusato_coupon/Update"),
          },
          {
            path: ':id/edit',
            name: ROUTES.ADMIN.FURUSATO_COUPON_UPDATE,
            meta: {breadcrumb: 'breadcrumbs.Furusato_coupon update', activeKey: 'admin.furusato_coupon'},
            component: () => import("@components/admin/furusato_coupon/Update"),
          }
        ]
      },
    ],
  }
];

import E404 from '@components/errors/E404.vue';
import LinkExpired from '@components/errors/LinkExpired';

export default [
  {
    path: '*',
    name: '404',
    component: E404
  },
  {
    path: '/not-found-page',
    name: 'not-found',
    component: E404
  },
  {
    path: '/link-expired',
    name: 'link-expired',
    component: LinkExpired
  },
];

<template>
  <div :class="['form-group app-form-group row', boundaryClass]">
    <label class="col-md-4 p-0 control-label  input-label">{{ label }}
      <span class="color-active app-text-bold"
            v-if="rules && rules.includes('required') && !rules.includes('required_if')">＊</span></label>
    <div :class="['col-md-8 form-group m-b-0 p-0']">
      <div class="radio radio-success">
        <template v-for="(radioInput) in radioInputs">
          <input type="radio" :checked="value === radioInput.value" :value="radioInput.value"
                 :name="id"
                 :disabled="disabled"
                 @input="$emit('input', $event.target.value)"
                 :id="id+radioInput.value">
          <label style="line-height: 13px !important;" class="app-text-bold" :for="id+radioInput.value">{{
              radioInput.label
            }}</label>
        </template>
        <div v-if="error_msg" class="error app-input-error-label-2">{{ error_msg }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import {v4} from 'uuid'

export default {
  props: [
    'name',
    'rules',
    'error_msg',
    'label',
    'value',
    'radioInputs',
    'boundaryClass',
    'disabled'
  ],
  data() {
    return {
      id: v4()
    }
  },
  watch: {
    value: function (newV, oldV) {
      if (newV !== oldV && newV === undefined) {
        _.forEach(this.radioInputs, radioInput => {
          this.$nextTick(() => {
            if ($('#' + this.id + radioInput.value)) {
              $('#' + this.id + radioInput.value).prop('checked', false)
            }
          })
        })
      }
    }
  }
}
</script>

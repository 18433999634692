import ROUTES from "@consts/routes";

export default [
  {
    path: 'dev-guide',
    name: ROUTES.DEV_GUIDE,
    meta: {breadcrumb: 'DEV GUIDE', activeKey: 'dev_guide'},
    component: () => import('@components/dev_guide/DevGuide'),
  },
];

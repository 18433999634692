<template>
  <div>
    <div v-if="success" class="alert alert-success" role="alert">
      <slot v-if="$slots.success" name="success"></slot>
      <span v-else v-html="success"></span>
    </div>
    <div v-if="error" class="alert alert-error" role="alert">
      <slot v-if="$slots.error" name="error"></slot>
      <span v-else v-html="error"></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      success: false,
      error: false,
    }
  },
  methods: {
    setSuccess(msg = true, timeout = 0) {
      this.clear();
      this.success = msg;
      if (timeout) {
        setTimeout(() => {
          this.success = false;
          this.error = false;
        }, timeout)
      }
    },
    setError(msg = true, timeout = 0) {
      this.clear();
      this.error = msg;
      if (timeout) {
        setTimeout(() => {
          this.success = false;
          this.error = false;
        }, timeout)
      }
    },
    clear() {
      this.error = false;
      this.success = false;
    }
  }
}
</script>

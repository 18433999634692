import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import i18n from '@i18n';
import '@services/validate/vee-validate';
import VueRouter from 'vue-router';
import Route from '@routes/routes';
import VueCookies from 'vue-cookies';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {localize} from "vee-validate";
import _ from 'lodash'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// CONSTANTS
import consts from '@consts'
Vue.prototype.$consts = consts

// CONFIG
import config from "@config";
Vue.prototype.$config = config

// OTHER PLUGIN
Vue.use(AppNotice);
Vue.use(VueLoading);
Vue.use(VueCookies);
Vue.use(RequestPlugin)
Vue.use(AuthPlugin)
App.i18n = i18n;
App.$cookies = VueCookies;
App.$localize = localize;
Vue.use(GlobalVariable);

// GLOBAL COMPONENTS
import GlobalVariable from "@services/plugins/GlobalVariable";
import AppMiddleware from "@services/middlewares/AppMiddleware";
import Store from '@services/store';
import RequestPlugin from '@services/plugins/Request';
import AuthPlugin from '@services/plugins/Auth';
import AppNotice from "@services/plugins/AppNotice";
import AppInput from "@components/_common/forms/AppInput";
import AppSelect from "@components/_common/forms/AppSelect";
import AppSelectMulti from "@components/_common/forms/AppSelectMulti";
import AppRadio from "@components/_common/forms/AppRadio";
import AppText from "@components/_common/forms/AppText";
import AppDatePicker from '@components/_common/forms/AppDatepicker';
import AppWeekPicker from '@components/_common/forms/AppWeekpicker';
import ConfirmModal from "@components/_common/Popup/ConfirmModal";
import Modal from "@components/_common/Popup/Modal";
import HeaderNotice from "@components/_common/HeaderNotice";
import AppPaginate from "@components/_common/AppPaginate";
import VueTimepicker from '@components/_common/forms/vue-timepicker';
import AppDateTimePicker from '@components/_common/forms/AppDateTimePicker';
import AppCheckbox from '@components/_common/forms/AppCheckbox';

Vue.component('AppPaginate', AppPaginate);
Vue.component('Modal', Modal);
Vue.component('ConfirmModal', ConfirmModal);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('AppSelect', AppSelect);
Vue.component('AppSelectMulti', AppSelectMulti);
Vue.component('AppInput', AppInput);
Vue.component('VueTimepicker', VueTimepicker);
Vue.component('AppText', AppText);
Vue.component('AppDatePicker', AppDatePicker);
Vue.component('AppDateTimePicker', AppDateTimePicker);
Vue.component('AppWeekPicker', AppWeekPicker);
Vue.component('AppRadio', AppRadio);
Vue.component('HeaderNotice', HeaderNotice);
Vue.component('AppCheckbox', AppCheckbox);

// STORE
Vue.prototype.$store = Store;

// Log
Vue.prototype.$log = console.log;

//REQUEST, ROUTER
Vue.prototype.$axios = axios
Vue.use(VueRouter);
Vue.router = Route;
Vue.router.pushByName = name => Vue.router.push({name});
App.router = Vue.router;
App.router.beforeEach((to, from, next) => {
  let language = App.$cookies.get(config.APP_NAME + 'lang');
  if (!language) {
    App.$cookies.set("lang", 'ja');
    language = 'ja';
  }

  App.$localize(language);
  App.i18n.locale = language;
  Vue.prototype.$store.commit('setting/setPrevRouter', Vue.prototype.$store.state.setting.currentRoute);
  Vue.prototype.$store.commit('setting/setCurrentRoute', to);
  //breadcrumbs start
  let breadcrumbs = to.matched.map(match => match.meta.breadcrumb)
  let routeNames = to.matched.map(match => match.name)
  var breadCrumbsGroups = [];
  _.forEach(breadcrumbs, (breadcrumb, index) => {
    if (breadcrumb) {
      if (_.isArray(breadcrumb)) {
        _.forEach(breadcrumb, (breadcrumbSub) => {
          breadCrumbsGroups = [...breadCrumbsGroups, {
            name: breadcrumbSub,
            routeName: routeNames[index]
          }]
        })
      } else {
        breadCrumbsGroups = [...breadCrumbsGroups, {
          name: breadcrumb,
          routeName: routeNames[index]
        }]
      }
    }
  })
  Vue.prototype.$store.commit('setting/setBreadCrumbs', breadCrumbsGroups);
  // middleware register
  let routerMiddlewares = to.matched.map(match => match.meta.middlewares)
  routerMiddlewares = _.spread(_.union)(_.compact(routerMiddlewares))
  let middlewares = [AppMiddleware, ...routerMiddlewares]
  const context = {to, from, next}
  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1)
  })
});

new Vue({...App, store: Store, el: '#app'});

/* run middleware chain */
function middlewarePipeline(context, middlewares, index) {
  const nextMiddleware = middlewares[index]

  if (!nextMiddleware) {
    return context.next
  }

  return async () => {
    const nextPipeline = middlewarePipeline(context, middlewares, index + 1)
    await nextMiddleware({...context, next: nextPipeline})
  }
}

import i18n from "@i18n";

export default {
  install(Vue, options) {
    const app = new Vue
    Vue.prototype.$appNotice = {
      success: function (message, options = {}) {
        $('.page-content-wrapper').pgNotification({
          style: 'flip',
          message: message,
          position: 'bottom-right',
          timeout: 3000,
          type: 'success',
          ...options
        }).show();
      },
      error: function (message, options = {}) {
        if (!message || message.length < 0) {
          message = i18n.tc('common.error')
        }
        $('.page-content-wrapper').pgNotification({
          style: 'flip',
          message: message,
          position: 'top-right',
          timeout: 3000,
          type: 'danger',
          ...options
        }).show();
      },
    }
  }
}

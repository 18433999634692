import axios from 'axios'
import API from '@consts/api';
import AuthConfig from '@consts/auth';
import Vue from 'vue';

export default function ({next}) {
  const app = new Vue;
  var authRole = app.$store.state.auth.currentAuth.role;
  console.log(`Axios Interceptors [${authRole}]____`)
  let BASE_API_URL = AuthConfig[authRole].api_url;
  var LOGIN_NAME = AuthConfig[authRole].routes.login;
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })

    failedQueue = [];
  }

  axios.interceptors.request.use(function (config) {
    let appToken = app.$store.state.auth.currentAuth.token;
    if (!config.headers.Authorization && appToken) {
      config.headers['Authorization'] = 'Bearer ' + appToken;
    }
    config.baseURL = BASE_API_URL;
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.data.msg &&
      error.response.data.msg === 'NOT_ACTIVE' &&
      error.response.status === API.UNAUTHORIZED_CODE
    ) {
      const app = new Vue;
      let role = app.$store.state.auth.currentAuth.role;
      app.$store.commit(`auth/removeAuth`)
      Vue.router.pushByName(AuthConfig[role].routes.login)
    }
    //console.log(error.response.status);
    const originalRequest = error.config;
    if ((error.response.status === API.UNAUTHORIZED_CODE ||
      error.response.status === API.FORBIDDEN_CODE) &&
      !originalRequest._retry) {
      if (error.response.data?.status === 'TOKEN_EXPIRED') {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({resolve, reject})
          }).then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          }).catch(err => {
            return Promise.reject(err);
          })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          var loader = app.$loading.appShow()
          axios({
            url: API.AUTH.REFRESH,
            method: API.METHOD_POST,
            baseURL: BASE_API_URL,
          }).then(response => {
            const token = response.headers.authorization;
            const expireTime = response.headers['token-expire-time'];
            app.$store.commit('auth/updateAuth', {
              token,
              expire_time: expireTime
            })
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            processQueue(null, token);
            loader?.hide()
            isRefreshing = false
            resolve(axios(originalRequest));
          }).catch((err) => {
            loader?.hide()
            isRefreshing = false
            processQueue(err, null);
            reject(err);
            app.$store.commit(`auth/removeAuth`)
            Vue.router.pushByName(LOGIN_NAME)
          })
        })
      } else {
        app.$store.commit(`auth/removeAuth`)
        return new Promise(function (resolve, reject) {
          loader?.hide()
          Vue.router.pushByName(LOGIN_NAME)
        }).then(() => {
          Promise.reject(error)
        })
      }
    }
    return Promise.reject(error);
  });
  app.$axios = axios;
  return next();
}

import {extend} from "vee-validate";
import ja from "../ja";

extend("after_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) > 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'は' + ja.names[obj.target] + 'より後の日付を指定してください。';
  }
});
extend("before_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) < 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'は' + ja.names[obj.target] + 'より前の日付を指定してください。';
  }
});

extend("after_eq_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) >= 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'は' + ja.names[obj.target] + '以降の日付を指定してください。';
  }
});

extend("before_eq_date", {
  params: ['target'],
  validate(value, {target}) {
    if (value && target) {
      return moment(value).diff(moment(target)) <= 0;
    }
    return true;
  },
  message(field, obj) {
    return ja.names[field] + 'は' + ja.names[obj.target] + '以前の日付を指定してください。';
  }
});

extend('numeric', {
  validate(value, args) {
    return (/^[0-9０-９]+$/).test(value);
  }
});
extend('min_price', {
  params: ['min'],
  validate(value, {min}) {
    if (value !== undefined && value !== null && value !== '') {
      value = Number(value.replaceAll(',', ''))
      return value >= Number(min)
    }
    return false
  }
});
extend('email', {
  validate(value) {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,40}$/.test(value)
  }
})
extend('password', {
  validate(value) {
    return /^(?=.*\d)(?=.*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,20}$/.test(value)
  }
})

extend('email_unique', {
  params: ['target'],
  validate(value, {target}) {
    return !target;
  },
})

extend('ja_postal_code', {
  params: ['target'],
  validate(value, {target}) {
    return value && value.length === 8 && !target;
  },
})

extend('phone', {
  validate(value) {
    return String(value).length === 10 || String(value).length === 11;
  },
})

extend('phone_mobile', {
  validate(value) {
    return String(value).length === 11;
  },
})

extend('phone_home', {
  validate(value) {
    return String(value).length === 10;
  },
})
extend('url', {
  validate(value) {
    return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig.test(value)
  },
  message: 'リンクではありません 。'
})
extend('fax', {
  validate(value) {
    return String(value).length === 10;
  },
})
extend('katakana', {
  validate(value) {
    return /^([ァ-ン]|ー)+$/.test(value)
  }
})
extend('no_space', {
  validate(value) {
    return /^\S+$/.test(value)
  }
})
extend('domain', {
  validate(value) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(value);
  },
  message: 'リンクではありません 。'
})
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{class:[_vm.inputStyle === 'normal' ? '' : 'app-form-group row', 'form-group', _vm.boundaryClass]},[(_vm.label && _vm.label.length)?_c('label',{class:[
          _vm.inputStyle === 'normal' ? 'm-b-5' : ('col-md-' + (_vm.colLabel ? _vm.colLabel : '4')),
           'input-label control-label p-0 p-r-5',
               _vm.labelClass]},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.label && _vm.label.length && _vm.rules && _vm.rules.includes('required') && !_vm.rules.includes('required_if') || _vm.is_require_flag)?_c('span',{staticClass:"color-active app-text-bold"},[_vm._v("＊")]):_vm._e()]):_vm._e(),((_vm.colInput || _vm.colLabel) && ((_vm.colInput ? parseInt(_vm.colInput) : 8) + (_vm.colLabel ? parseInt(_vm.colLabel) : 4) < 12))?_c('div',{class:'col-md-' + (12 - (_vm.colInput ? _vm.colInput : 8) - (_vm.colLabel ? _vm.colLabel : 4))}):_vm._e(),_c('div',{class:[
        _vm.inputStyle === 'normal' ? '' : ('col-md-' + (_vm.colInput ? _vm.colInput : '8')),
        'form-group m-b-0 p-0',
        invalid && validated ? 'has-error' : '',
        _vm.disabled ? 'form-disabled' : ''
      ]},[_c('div',{class:[_vm.$slots.append ? 'input-group' : '']},[(_vm.vcleave)?_c('input',{directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.vcleave),expression:"vcleave"}],class:['form-control', invalid && validated ? 'error' : ''],attrs:{"type":_vm.type,"autocomplete":_vm.autocomplete || 'off',"disabled":_vm.disabled,"maxlength":_vm.maxLength,"placeholder":_vm.placeholder,"cleave-regex":_vm.cleaveRegex},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":_vm.blur}}):_c('input',{class:['form-control', invalid && validated ? 'error' : ''],attrs:{"type":_vm.type,"autocomplete":_vm.autocomplete || 'off',"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":_vm.blur}}),(_vm.$slots.append)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text text-dark bg-white"},[_vm._t("append")],2)]):_vm._e()]),(invalid && validated)?_c('label',{staticClass:"error app-input-error-label-2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
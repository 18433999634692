export default {
	MAX_LENGTH: 191,
	MAX_LENGTH_URL: 500,
	MAX_LENGTH_TEXT: 10000,
  DEFAULT_BUDGET: [
    {
      name: "",
      quantity: "",
      price: "",
    }
  ],
  ACTIVE: 1,
  INACTIVE: 0,
  BOOKING_COMPLETE: 4,
  SPACE_FULL_SIZE: '　',
  TYPE_PUBLIC: 1,
  TYPE_DRAFT: 2,
  TYPE_CHANGE_STATUS: 3,

  TYPE_PUBLISH_ALL: 1,
  TYPE_DRAFT_ALL: 2,
  TYPE_DELETE_ALL: 3,
  TYPE_DELETE_SINGLE: 0,


  TYPE_DISCOUNT_PRICE: 0,
  TYPE_PRODUCT_PRICE: 1,
  TYPE_DISCOUNT_DEFAULT: 2,

  TYPE_NORMAL: 1,
  TYPE_FURUSATO_NOZEI: 2
}

import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'offices',
    name: ROUTES.ADMIN.OFFICES,
    meta: {breadcrumb: 'breadcrumbs.offices', activeKey: 'admin.offices'},
    component: () => import("@components/admin/offices/Index"),
  }
];

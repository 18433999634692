import api from '@consts/api'
import role from '@consts/role'
import routes from '@consts/routes'
import input from '@consts/input'

export default {
  API: api,
  ROLES: role,
  ROUTES: routes,
  INPUT: input,
}

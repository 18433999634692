import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'office_plans',
    name: ROUTES.ADMIN.OFFICE_PLANS,
    meta: {breadcrumb: 'breadcrumbs.office_plans', activeKey: 'admin.office_plans'},
    component: () => import("@components/admin/office_plans/Index"),
  },
];

/*admin route*/
import AdminRoute from './admin';

/*exception route*/
import VueRouter from 'vue-router';
import ErrorsRoute from './errors/ErrorsRoute';

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...ErrorsRoute,
    ...AdminRoute,
  ]
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;

import {AppLocalStorage} from "@services/utils";
import config from "@/config";
import Vue from "vue";

export default function ({next, to}) {
  console.log(`____App Middleware____`)
  let app = new Vue();
  /* when route change, app version will be detect then reload location) */
  // const appVersionChange = AppLocalStorage.getItem('appVersionChange');
  // const appVersion = AppLocalStorage.getItem('appVersion');
  // app.$request.get(config.APP_VERSION_URL, null, {noLoadingEffect: true}).then(res => {
  //   if (!res.hasErrors()) {
  //     let newVer = res.data;
  //     if (appVersion != newVer) {
  //       AppLocalStorage.setItem('appVersionChange', 'changed');
  //       AppLocalStorage.setItem('appVersion', newVer);
  //       console.log('___ app version NEW VERSION:' + newVer);
  //     }
  //   }
  // })
  // console.log('___ app version STATUS:' + appVersionChange + ' | LOCAL VERSION:' + appVersion)
  // if (appVersionChange === 'changed') {
  //   AppLocalStorage.setItem('appVersionChange', 'not-changed');
  //   return window.location.replace(Vue.router.resolve({
  //     name: to.name,
  //     params: to.params,
  //     query: to.query
  //   }).href)
  // }
  return next();
}

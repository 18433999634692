import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}
export default [
  {
    path: '',
    name: ROUTES.ADMIN.BOOKINGS,
    meta: {
      breadcrumb: i18n.tc('breadcrumbs.Booking'),
      activeKey: 'admin.bookings'
    },
    component: routerView,
    children: [
      {
        path: 'furusato-product',
        name: ROUTES.ADMIN.FURUSATO_PRODUCT,
        meta: {breadcrumb: 'breadcrumbs.Furusato_product', activeKey: 'admin.furusato_product_list'},
        component: routerView,
        children: [
          {
            path: '',
            name: ROUTES.ADMIN.FURUSATO_PRODUCT,
            meta: {activeKey: 'admin.furusato_product_list'},
            component: () => import("@components/admin/furusato_product/Index"),
          },
          {
            path: 'create',
            name: ROUTES.ADMIN.FURUSATO_PRODUCT_CREATE,
            meta: {breadcrumb: 'breadcrumbs.Furusato_product add', activeKey: 'admin.furusato_product'},
            component: () => import("@components/admin/furusato_product/Update"),
          },
          {
            path: ':id/edit',
            name: ROUTES.ADMIN.FURUSATO_PRODUCT_UPDATE,
            meta: {breadcrumb: 'breadcrumbs.Furusato_product update', activeKey: 'admin.furusato_product'},
            component: () => import("@components/admin/furusato_product/Update"),
          }
        ]
      },
    ]
  }
];

export const setPrevRouter = (state, opened) => {
  state.prevRouter = opened
}
export const setCurrentRoute = (state, opened) => {
  state.currentRoute = opened
}
export const setServerSettings = (state, opened) => {
  state.serverSettings = opened
}

export const setBreadCrumbs = (state, opened) => {
  state.breadcrumbs = opened
}

export const updateBreadCrumb = (state, opened) => {
  _.forEach(state.breadcrumbs, (breadcrumb, index) => {
    let breadcrumbName = breadcrumb.name;
    let regexs = breadcrumbName.match(/\{\w+\}/g)
    if (regexs) {
      _.forEach(regexs, regex => {
        let keyName = regex.slice(1, -1);
        state.breadcrumbParams[keyName] = opened[keyName]
      })
    }
  })
  state.breadcrumbParams = _.clone(state.breadcrumbParams)
}

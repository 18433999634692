import API from '@consts/api';
import Vue from "vue";
import AuthConfig from '@consts/auth'

export default function ({next}) {
  console.log('____AdminAuthenticated____')
  let app = new Vue();
  let user = app.$auth.user();
  let expireTime = app.$store.state.auth.currentAuth.expire_time;
  let role = app.$store.state.auth.currentAuth.role;
  if (user && user.role === AuthConfig[role].role_id) {
    if (expireTime <= moment().valueOf()) {
      return Vue.prototype.$request.get(API.PROFILE.USER).then(() => {
        next()
      }).catch(() => {
      })
    } else {
      return next()
    }
  } else {
    Vue.router.pushByName(AuthConfig[role].routes.login)
  }
}

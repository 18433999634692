<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors, invalid, validated }">
    <div :class="[inputStyle === 'normal' ? '' : 'app-form-group row', 'form-group', boundaryClass]">
      <label v-if="label && label.length"
        :class="[inputStyle === 'normal' ? '' : ('col-md-' + (colLabel ? colLabel : '4')), 'control-label input-label p-0', labelClass]">
        {{ label }}
        <span class="color-active app-text-bold"
              v-if="rules && rules.includes('required') && !rules.includes('required_if')">＊</span>
      </label>
      <div
        v-if="(colInput || colLabel) && ((colInput ? parseInt(colInput) : 8) + (colLabel ? parseInt(colLabel) : 4) < 12)"
        :class="'col-md-' + (12 - (colInput ? colInput : 8) - (colLabel ? colLabel : 4))">
      </div>
      <div
        :class="[
          inputStyle === 'normal' ? '' : ('col-md-' + (colInput ? colInput : '8')),
          'form-group m-b-0 p-0',
          invalid && validated ? 'has-error' : '',
          disabled ? 'form-disabled' : ''
        ]">
        <div class="input-group">
          <input style="color: #2c2c2c !important;" type="text" class="form-control" ref="datepicker"
                 :value="value" readonly="readonly"
                 :disabled="disabled"
                 @input="$emit('input', $event.target.value)">
          <div class="input-group-append">
              <span class="input-group-text text-dark bg-white" @click="trigger"><i
                class="fa fa-calendar"></i></span>
          </div>
        </div>
        <label v-if="invalid && validated" class="error app-input-error-label-2">{{ errors[0] }}</label>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  props: [
    'options',
    'value',
    'name',
    'colLabel',
    'colInput',
    'label',
    'rules',
    'onChange',
    'value',
    'boundaryClass',
    'inputStyle',
    'labelClass',
    'disabled',
  ],
  mounted: function () {
    let vm = this;
    $(this.$refs.datepicker).val(this.value);
    $(this.$refs.datepicker).datepicker({
      autoclose: true,
      language: 'ja',
      format: 'yyyy/mm/dd',
      todayHighlight: true,
      weekStart: 1,
      ...this.options
    }).on('change', function (ev, args) {
      vm.$emit('input', $(this).val());
    }).on('hide', function (e) {
      e.stopPropagation();
    });
  },
  watch: {
    /*on change value*/
    value: function (value, oldValue) {
      let vm = this;
      if (this.options && this.options.format === 'yyyy/mm') {
        var formatDateValue = this.value;
      } else {
        var formatDateValue = this.value ? this.formatDate(this.value) : this.value;
      }
      $(this.$refs.datepicker).datepicker('update', value);
      $(this.$refs.datepicker).val(formatDateValue).trigger('change');
      if(value !== oldValue) {
        this.onChange ? this.onChange(value) : ''
      }
      vm.$emit('change', formatDateValue);
    },
    /*on change options*/
    options: function (options) {

    }
  },
  methods: {
    trigger () {
      this.$refs.datepicker.focus();
    }
  },
  destroyed: function () {
    $(this.$refs.datepicker).datepicker('destroy');
  }
};
</script>

import ROUTES from '@consts/routes';
import AuthSettingMiddleware from "@services/middlewares/AuthSettingMiddleware";
import AxiosBaseNew from "@services/middlewares/AxiosBaseNew";

export default [
  {
    path: '/admin/auth',
    meta: {
      middlewares: [AuthSettingMiddleware('admin'), AxiosBaseNew],
    },
    component: () => import('@components/base_auths/LayoutAuth'),
    children: [
      {
        path: 'login',
        name: ROUTES.ADMIN.AUTH.LOGIN,
        component: () => import("@components/base_auths/Login"),
      },
      {
        path: 'forgot-password',
        name: ROUTES.ADMIN.AUTH.FORGOT_PASSWORD,
        component: () => import("@components/base_auths/ForgotPassword"),
      },
      {
        path: 'reset-password',
        name: ROUTES.ADMIN.AUTH.RESET_PASSWORD,
        component: () => import("@components/base_auths/ResetPassword"),
      },
    ],
  }
]

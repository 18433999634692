<template>
  <div class="d-flex justify-content-center full-height full-width align-items-center">
    <div class="error-container text-center">
      <h1 class="error-number">404</h1>
      <h2 class="semi-bold">{{$t('pages.404_msg')}}</h2>
    </div>
  </div>
</template>
<script>
  import ROUTES from "@consts/routes";
  export default {
    created() {
      this.$router.push({name: ROUTES.ADMIN.BOOKINGS})
    }
  };
</script>

import {AppLocalStorage} from "@services/utils";
import configRoles from "@consts/auth"

export default function () {
  var data = {};
  _.forEach(Object.keys(configRoles), role => {
    data[role] = {
      role: role,
      user: JSON.parse(AppLocalStorage.getItem(`${role}_auth.user`)),
      token: AppLocalStorage.getItem(`${role}_auth.token`),
      expire_time: Number(AppLocalStorage.getItem(`${role}_auth.expire_time`))
    }
  })
  return {
    currentAuth: {
      role: null,
      user: null,
      token: null,
      expire_time: null,
    },
    data: data,
  }
}

import ROUTES from "@consts/routes";
import UserRoute from "@routes/admin/UserRoute";
import AuthRoute from "@routes/admin/AuthRoute";
import HomeRoute from "@routes/admin/HomeRoute";
import AuthAccess from "@services/middlewares/AuthAccess";
import AuthSettingMiddleware from "@services/middlewares/AuthSettingMiddleware";
import AxiosBaseNew from "@services/middlewares/AxiosBaseNew";
import DevGuide from "@routes/DevGuide";
import TourOrderSettingRoute from "./TourOrderSettingRoute";
import MemberRoute from "./MemberRoute";
import OfficeRoute from "./OfficeRoute";
import BookingRoute from "@routes/admin/BookingRoute";
import FurusatoProductRoute from "./FurusatoProductRoute";
import FurusatoCouponRoute from "./FurusatoCouponRoute";
import FurusatoCouponUseRoute from "./FurusatoCouponUseRoute";
import OfficePlanRoute from "./OfficePlanRoute";

export default [
  ...AuthRoute,
  {
    path: '/admin',
    meta: {
      middlewares: [AuthSettingMiddleware('admin'), AxiosBaseNew, AuthAccess],
    },
    component: () => import('@components/_partials/Layout'),
    children: [
      {
        path: '',
        redirect: {name: ROUTES.ADMIN.BOOKINGS}
      },
      {
        path: 'profile',
        name: ROUTES.ADMIN.PROFILE,
        meta: {breadcrumb: 'breadcrumbs.Profile', activeKey: 'admin.profile'},
        component: () => import("@components/base_auths/Profile"),
      },
      ...DevGuide,
      ...UserRoute,
      ...HomeRoute,
      ...TourOrderSettingRoute,
      ...MemberRoute,
      ...OfficeRoute,
      ...BookingRoute,
      ...FurusatoProductRoute,
      ...FurusatoCouponRoute,
      ...FurusatoCouponUseRoute,
      ...OfficePlanRoute
    ],
  }
]

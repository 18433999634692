export default {
  METHOD_GET: 'GET',
  METHOD_POST: 'POST',
  METHOD_UPDATE: 'PATCH',
  METHOD_DELETE: 'DELETE',
  UNAUTHORIZED_CODE: 401,
  FORBIDDEN_CODE: 403,
  NOTFOUND_CODE: 404,
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    REFRESH: '/auth/refresh',
    FORGOT_PASSWORD: '/auth/forgot-password',
    RESET_PASSWORD: '/auth/reset-password',
    CHECK_PARAMS_RESET_PASSWORD: '/auth/check-params-reset-password',
  },
  PROFILE: {
    USER: '/profile/user',
    UPDATE_PROFILE: '/profile/update_profile',
  },
  ADMIN: {
    RESENT_ACTIVE_EMAIL: userId => `resent_active_email/${userId}`,
    UPLOAD_IMAGE: '/tour_order_templates/upload_file',
    UPLOAD_IMAGE_OFFICES: '/offices/upload_file',
    UPLOAD_IMAGE_PRODUCT: '/products/upload_file',
    UPLOAD_IMAGE_OFFICE_PLANS: '/office_plans/upload_file',
    // USER
    USERS_LIST: '/users',
    USERS_DELETE: id => `/users/${id}`,
    USERS_EDIT: id => `/users/${id}`,
    USERS_SAVE: '/users',
    USERS_SELECT_NAME: '/users_name',
    USERS_SELECT: '/users_list_select',

    TOUR_ORDER_TEMPLATES: '/tour_order_templates',
    TOUR_ORDER_TEMPLATES_DELETE: id => `/tour_order_templates/${id}`,
    TOUR_ORDER_TEMPLATES_EDIT: id => `/tour_order_templates/${id}`,
    TOUR_ORDER_TEMPLATES_SAVE: '/tour_order_templates',
    TOUR_ORDER_TEMPLATES_NAME: 'tour_order_templates/autocomplete/',
    TOUR_PREFECTURES: '/prefectures/list_select',
    TOUR_PREFECTURES_CITIES: '/prefectures/list_select_cities',
    TOUR_CITIES: '/cities/list_select',
    TOUR_FULL_CITIES: '/cities/list_full_select',
    TOUR_ORDERS_SELECT_NAME: '/tour_order_templates_select_name',
    TOUR_ORDER_TEMPLATES_EXPORT: 'tour_order_templates/exportCSV',

    OFFICES_LIST: '/offices',
    OFFICES_LIST_NAME: '/offices/list_select',
    OFFICES_LIST_SELECT_NAME: '/offices_list_select_name',
    OFFICES_LIST_SELECT_COMPANY_NAME: '/offices_list_select_company_name',
    OFFICES_DELETE: id => `/offices/${id}`,
    OFFICES_SAVE: '/offices',
    OFFICES_EDIT: id => `/offices/${id}`,
    OFFICES_SEARCH: '/offices/autocomplete',
    OFFICES_EXPORT: 'offices/exportCSV',

    OFFICE_PLANS_LIST: '/office_plans',
    OFFICE_PLANS_LIST_NAME: id => `/office_plans/list_select/${id}`,
    OFFICES_LIST_PLAN_NAME: '/office_plans/list_all_plans',
    OFFICE_PLANS_DELETE: id => `/office_plans/${id}`,
    OFFICES_PLAN_SAVE: '/office_plans',
    OFFICE_PLANS_EDIT: id => `/office_plans/${id}`,
    UI_HELPER_OFFICE_PLANS_LIST: `ui_helper/office_plans`,
    UI_HELPER_OFFICE_SCHEDULE_LIST: `ui_helper/office_schedule`,
    UI_HELPER_PLANS_LIST: `ui_helper/plan_list`,

    CONFIG_LIST: '/configs',
    UI_HELPER_BOOKING: 'ui_helper/booking',
    UI_HELPER_TOUR_ORDER: 'ui_helper/tour_order_select',
    BOOKING_LIST: 'bookings',
    BOOKING_CREATE: 'bookings',
    BOOKING_UPDATE: id => `bookings/${id}`,
    BOOKING_SHOW: id => `bookings/${id}`,
    BOOKING_DELETE: id => `bookings/${id}`,
    BOOKING_UPDATE_PAYMENT: `bookings/payment-status`,
    B_TOUR_ORDER_EDIT: id => `/booking_tour_orders/${id}`,
    BOOKING_HISTORY: id => `booking_tour_orders/schedule-history/${id}`,
    BOOKING_EXPORT: 'bookings/exportCSV',
    BOOKING_INFORMATION: id => `booking-information/${id}`,

    MEMBERS_LIST: '/members',
    MEMBERS_EDIT: id => `/members/${id}`,
    MEMBERS_DELETE: id => `/members/${id}`,
    MEMBERS_SELECT_NAME: '/members/list_select_name',
    MEMBERS_EXPORT: "members/exportCSV",

    SUPPORT_LIST: '/supports_histories',
    SUPPORT_SAVE: '/supports_histories',
    SUPPORT_EDIT: id => `/supports_histories/${id}`,

    FURUSATO_PRODUCT: '/furusato_product',
    FURUSATO_PRODUCT_SAVE: '/furusato_product',
    FURUSATO_OFFICES_LIST_SELECT_NAME: '/furusato_product_list_select_name',
    FURUSATO_PRODUCT_DELETE: id => `/furusato_product/${id}`,
    FURUSATO_PRODUCT_EDIT: id => `/furusato_product/${id}`,
    UI_HELPER_FURUSATO_PRODUCT: 'ui_helper/furusato_product',
    FURUSATO_PRODUCT_PUBLISH_ALL: 'furusato_product/update-product',
    FURUSATO_PRODUCT_DRAFT_ALL: 'furusato_product/update-product',
    FURUSATO_PRODUCT_DELETE_ALL: 'furusato_product/delete-product',
    FURUSATO_PRODUCT_DELETE_ALL_CHECK: 'furusato_product/delete-product-all',
    CHANGE_STATUS_FURUSATO_PRODUCT: 'furusato_product/update-product',

    FURUSATO_COUPON: '/furusato_coupon',
    FURUSATO_PRODUCT_LIST_SELECT_NAME: '/furusato_product_list',
    FURUSATO_COUPON_SAVE: '/furusato_coupon',
    FURUSATO_COUPON_DELETE: id => `/furusato_coupon/${id}`,
    FURUSATO_COUPON_EDIT: id => `/furusato_coupon/${id}`,
    FURUSATO_COUPON_DELETE_ALL: 'furusato_coupon/delete-coupon',
    UI_HELPER_FURUSATO_COUPON: 'ui_helper/furusato_coupon',
    FURUSATO_COUPON_PUBLISH_ALL: 'furusato_coupon/update-coupon',
    FURUSATO_COUPON_DRAFT_ALL: 'furusato_coupon/update-coupon',
    FURUSATO_COUPON_DATA_EDIT: id => `/furusato_coupon/${id}`,

    FURUSATO_COUPON_USE: '/furusato_coupon_use',
    UI_HELPER_FURUSATO_COUPON_USE: 'ui_helper/furusato_coupon_use',
    UI_HELPER_OFFICE_LIST: id => `ui_helper/office_list/${id}`,
    UI_HELPER_OFFICES: 'ui_helper/offices',
    UI_HELPER_PRODUCT_LIST: `ui_helper/product_list`
  },
}

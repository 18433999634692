import _ from "lodash";
import {divide, round, ceil, multiply} from "mathjs";
import Cleave from "cleave.js";

export default {
  install(Vue, options) {

    Vue.directive('cleave', {
      inserted: (el, binding) => {
        var value = binding.value;
        el.cleave = new Cleave(el, value || {})
      },
      update: (el) => {
        const event = new Event('input', {bubbles: true});
        var delimiter = el.cleave.properties.delimiter;
        if (delimiter && delimiter === '-') {
          setTimeout(function () {
            var value = el.value;
            var cleaveRegex = el.getAttribute('cleave-regex');
            if (cleaveRegex && cleaveRegex.length && value && value.length) {
              value = value.replace(new RegExp(cleaveRegex, 'gm'), '');
            }
            el.cleave.setRawValue(value);
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
          }, 0)
        } else {
          var value = el.value;
          var cleaveRegex = el.getAttribute('cleave-regex');
          if (cleaveRegex && cleaveRegex.length && value && value.length) {
            value = value.replace(new RegExp(cleaveRegex, 'gm'), '');
          }
          el.cleave.setRawValue(value);
          el.value = el.cleave.properties.result
          el.dispatchEvent(event)
        }
      }
    })

    const V_VARCHAR_MAXLENGTH = 191
    Vue.prototype.V_EMAIL_REQUIRED = 'required|email|max:' + V_VARCHAR_MAXLENGTH;
    Vue.prototype._ = _
    Vue.prototype.$mathjs = {divide, round, ceil, multiply}

    Vue.prototype.$loading.appShow = (options = {}) => {
      try {
        $('.vld-overlay').remove();
      } catch (e) {
      }
      return Vue.prototype.$loading.show({
        loader: 'bars', opacity: 0.3, color: 'rgba(1, 92, 172, 1)', width: 96, height: 96, ...options
      })
    }
    Vue.prototype.formatDate = (dateString) => {
      if (!dateString || String(dateString).length == 0) {
        return '';
      }
      let date = new Date(dateString);
      return date.getFullYear() + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + ("0" + date.getDate()).slice(-2);
    }
    Vue.prototype.setOptionToolFilter = (tools, name, type, options) => {
      let filters = tools?.search?.filters;
      if (!filters) {
        return;
      }
      let newFilter = filters.map(filter => {
        if (filter.name === name && filter.type === type) {
          return {...filter, options: options}
        } else {
          return filter
        }
      })
      tools.search.filters = _.cloneDeep(newFilter);
    }
    Vue.prototype.getOptionFilter = (filters, name, type, options) => {
      if (!filters) {
        return;
      }
      let newFilter = filters.map(filter => {
        if (filter.name === name && filter.type === type) {
          return {...filter, options: options}
        } else {
          return filter
        }
      })
      return _.cloneDeep(newFilter);
    }
    Vue.prototype.formatNumber = (value) => {
      if (!value) {
        return value;
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    Vue.prototype.formatPriceJa = (value) => {
      return Vue.prototype.formatNumber(value) + '円';
    }
    Vue.prototype.getDayFromDate = (date) => {
      if (!date) {
        return null;
      }
      const mapDay = {
        0: '日',
        1: '月',
        2: '火',
        3: '水',
        4: '木',
        5: '金',
        6: '土',
      }
      const day = (new Date(date)).getDay()
      return {
        'text': mapDay[day],
        'code': day,
      };
    }
    Vue.prototype.downloadFromUrl = url => {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
    Vue.prototype.checkImage = image => {
      return ['image/png', 'image/jpeg', 'image/jpg'].indexOf(image.type) < 0
    }
  }
}

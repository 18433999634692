import Config from "@config";

export const AppLocalStorage = {
  setItem: (key, value) => {
    return localStorage.setItem(Config.APP_NAME + key, value)
  },
  getItem: (key) => {
    return localStorage.getItem(Config.APP_NAME + key)
  },
  removeItem: (key) => {
    return localStorage.removeItem(Config.APP_NAME + key)
  },
}

export const AppDateTime = {
  getYears: () => {
    var options_years = []
    let year = new Date().getFullYear();
    for(let i = year; i <= 2050; i++) {
      options_years.push({"id": i, "name": i})
    }
    return options_years
  },
  getMonths: () => {
    var options_months = []
    for (let i = 1 ; i <= 12 ; i++) {
      options_months.push({"id": i, "name": i})
    }
    return options_months
  },
  getHours() {
    var options_hours = []
    for(let i = 0 ; i <= 23 ; i++) {
      if (i <= 9) {
        i = '0' + i
      }
      options_hours.push({"id": i, "name": i})
    }
    return options_hours
  },
  getMinutes() {
    var options_minutes = []
    for(let i = 0 ; i <= 59 ; i++) {
      if (i <= 9) {
        i = '0'+ i
      }
      options_minutes.push({"id": i, "name": i})
    }
    return options_minutes
  },
}

export const AppNum = {
  getNums: () =>  {
    var options_nums = []
    for (let i = 1; i <= 99; i++) {
      options_nums.push({"id": i, "name": i})
    }
    return options_nums
  },
}

import ja from "vee-validate/dist/locale/ja.json";

ja.names = {
  name: '名前',
  email: 'メールアドレス',
  phone: "電話番号",
  password: 'パスワード',
  password_confirm: 'パスワード（確認）',
  role: "ロール",
  address: "住所",
  fax: "FAX",
  new_password: "新しいパスワード",
  new_password_confirm: "新しいパスワード（確認）",
  new_confirm: "新しいパスワード（確認）",
  current_password: "現在のパスワード",
  current_email: "現在のメールアドレス",
  new_email: "新メールアドレス",
  start_date: "旅行日程開始日",
  end_date: "旅行日程終了日",
  login_email: "このメールアドレス",
  price: "料金",
  application_deadline: "申込〆切",
  payment_deadline: "入金〆切",
  postal_code: "郵便番号",
  content: "対応メモ",
  hearing_tour_purpose: "対応メモ",
  hearing_room_allocation: "宿 / 部屋割り",
  hearing_meal_note: "食事 / アレルギー対応",
  hearing_tour_note: "体験サービスなどの希望",
  hearing_other_note: "その他特別な要望",
};

ja.messages = {
  ...ja.messages,
  "alpha": "{_field_}はアルファベットのみ使用できます。",
  "alpha_num": "{_field_}は英数字のみ使用できます。",
  "alpha_dash": "{_field_}は英数字とハイフン、アンダースコアのみ使用できます。",
  "alpha_spaces": "{_field_}はアルファベットと空白のみ使用できます。",
  "between": "{_field_}は{min}から{max}の間でなければなりません。",
  "confirmed": "{_field_}が一致しません。",
  "digits": "{_field_}は{length}桁の数字でなければなりません。",
  "dimensions": "{_field_}は幅{width}px、高さ{height}px以内でなければなりません。",
  "email": "このメールアドレスは有効なメールアドレスではありません。",
  "excluded": "{_field_}は不正な値です。",
  "ext": "{_field_}は有効なファイル形式ではありません。",
  "image": "{_field_}は有効な画像形式ではありません。",
  "integer": "{_field_}は整数のみ使用できます。",
  "is": "{_field_}が一致しません。",
  "is_not": "{_field_}は無効です。",
  "length": "半角数字{length}桁の形式で入力してください。",
  "max_value": "こちらに{max}以下の数字を入力してください。",
  "max": "{_field_}は{length}文字以内にしてください。",
  "mimes": "{_field_}は有効なファイル形式ではありません。",
  "min_value": "こちらに{min}以上の数字を入力してください。",
  "min": "{_field_}は{length}文字以上でなければなりません。",
  "numeric": "{_field_}は数字のみ使用できます。",
  "oneOf": "{_field_}は有効な値ではありません。",
  "regex": "{_field_}のフォーマットが正しくありません。",
  "required": "こちらは必須項目です。",
  "required_if": "こちらは必須項目です。",
  "size": "{_field_}は{size}KB以内でなければなりません。",
  "double": "{_field_}フィールドは有効な10進数である必要があります。",
  required_noname: 'こちらは必須項目です。',
  password: 'パスワードは8文字以上~20文字以下半角英数字で、大文字、小文字、数字を全て含む必要があります。',
  phone: '電話番号は10桁または11桁の半角数字で入力してください。',
  phone_home: '自宅番号は半角数字10桁の形式で入力してください。',
  phone_mobile: '携帯番号は半角数字11桁の形式で入力してください。',
  fax: "FAXは半角数字10桁の形式で入力してください。",
  ja_postal_code: "有効な郵便番号を入力してください。",
  email_unique: "このメールアドレスは既に使用されています。",
  katakana: "カタカナしかない",
  no_space: "にはスペースを入力しないでください",
  "min_price": "こちらに{min}以上の数字を入力してください。",
}

export default ja

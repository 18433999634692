import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

const routerView = {
  render(c) {
    return c("router-view");
  }
}
export default [
  {
    path: '',
    name: ROUTES.ADMIN.BOOKINGS,
    meta: {
      breadcrumb: i18n.tc('breadcrumbs.Booking'),
      activeKey: 'admin.bookings'
    },
    component: routerView,
    children: [
      {
        path: 'furusato-coupon-use',
        name: ROUTES.ADMIN.FURUSATO_COUPON_USE,
        meta: {breadcrumb: 'breadcrumbs.Furusato_coupon use', activeKey: 'admin.furusato_coupon_use'},
        component: () => import("@components/admin/furusato_coupon_use/Index"),
      }
    ]
  }
];

import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  {
    path: 'members',
    name: ROUTES.ADMIN.MEMBERS,
    meta: {breadcrumb: 'breadcrumbs.members', activeKey: 'admin.members'},
    component: () => import("@components/admin/members/Index"),
  },
  {
    path: 'members/:id/edit',
    name: ROUTES.ADMIN.MEMBERS_UPDATE,
    meta: {breadcrumb: 'breadcrumbs.members', activeKey: 'admin.members'},
    component: () => import("@components/admin/members/Update"),
  },
];

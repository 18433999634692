import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [

  {
    path: 'tour_orders',
    name: ROUTES.ADMIN.TOUR_ORDERS,
    meta: {breadcrumb: 'breadcrumbs.Tour_orders', activeKey: 'admin.tour_orders'},
    component: () => import("@components/admin/tour_order_templates/Index"),
  },
  {
    path: 'tour_orders/create',
    name: ROUTES.ADMIN.TOUR_ORDERS_CREATE,
    meta: {breadcrumb: 'breadcrumbs.Tour_orders add', activeKey: 'admin.tour_orders'},
    component: () => import("@components/admin/tour_order_templates/Update"),
  },
  {
    path: 'tour_orders/:id/edit',
    name: ROUTES.ADMIN.TOUR_ORDERS_UPDATE,
    meta: {breadcrumb: 'breadcrumbs.Tour_orders update', activeKey: 'admin.tour_orders'},
    component: () => import("@components/admin/tour_order_templates/Update"),
  },
];

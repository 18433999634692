import Config from '@config'
import ROUTES from '@consts/routes';

export default {
  admin: {
    api_url: Config.ADMIN_API_URL,
    role_id: 1,
    routes: {
      login: ROUTES.ADMIN.AUTH.LOGIN,
      profile: ROUTES.ADMIN.PROFILE,
      dashboard: ROUTES.ADMIN.DASHBOARD,
      reset_password: ROUTES.ADMIN.AUTH.RESET_PASSWORD,
      forgot_password: ROUTES.ADMIN.AUTH.FORGOT_PASSWORD,
      bookings: ROUTES.ADMIN.BOOKINGS,
    }
  },
  user: {
    role: 'user',
  }
}

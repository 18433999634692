import ROUTES from "@consts/routes";
import i18n from "@/lang/i18n";

export default [
  	{
    	path: 'dashboard',
    	name: ROUTES.ADMIN.DASHBOARD,
    	meta: {breadcrumb: 'breadcrumbs.Home', activeKey: 'admin.dashboard'},
    	component: () => import("@components/admin/dashboard/Dashboard"),
  	}
];

<template>
  <ValidationProvider :name="name" :rules="rules" v-slot="{ errors, invalid, validated }">
    <div :class="[inputStyle === 'normal' ? '' : 'app-form-group row', 'form-group', boundaryClass]">
      <label v-if="label && label.length" :class="[
            inputStyle === 'normal' ? 'm-b-5' : ('col-md-' + (colLabel ? colLabel : '4')),
             'input-label control-label p-0 p-r-5',
                 labelClass]">
        {{ label }}
        <span class="color-active app-text-bold"
              v-if="label && label.length && rules && rules.includes('required') && !rules.includes('required_if') || is_require_flag">＊</span>
      </label>
      <div
        v-if="(colInput || colLabel) && ((colInput ? parseInt(colInput) : 8) + (colLabel ? parseInt(colLabel) : 4) < 12)"
        :class="'col-md-' + (12 - (colInput ? colInput : 8) - (colLabel ? colLabel : 4))">
      </div>
      <div
        :class="[
          inputStyle === 'normal' ? '' : ('col-md-' + (colInput ? colInput : '8')),
          'form-group m-b-0 p-0',
          invalid && validated ? 'has-error' : '',
          disabled ? 'form-disabled' : ''
        ]">
        <div :class="[$slots.append ? 'input-group' : '']">
          <input v-if="vcleave" :type="type"
                 :class="['form-control', invalid && validated ? 'error' : '']"
                 :autocomplete="autocomplete || 'off'"
                 :value="value"
                 @input="$emit('input', $event.target.value)"
                 :disabled="disabled"
                 :maxlength="maxLength"
                 :placeholder="placeholder"
                 @blur="blur"
                 v-cleave="vcleave"
                 :cleave-regex="cleaveRegex"
          >
          <input v-else :type="type"
                 :class="['form-control', invalid && validated ? 'error' : '']"
                 :autocomplete="autocomplete || 'off'"
                 :value="value"
                 @input="$emit('input', $event.target.value)"
                 :placeholder="placeholder"
                 :disabled="disabled"
                 @blur="blur"
                 :maxlength="maxLength"
          >
          <div v-if="$slots.append" class="input-group-append">
            <span class="input-group-text text-dark bg-white">
              <slot name="append"></slot>
            </span>
          </div>
        </div>
        <label v-if="invalid && validated" class="error app-input-error-label-2">{{ errors[0] }}</label>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  props: [
    'name',
    'label',
    'type',
    'rules',
    'value',
    'change',
    'placeholder',
    'disabled',
    'autocomplete',
    'inputStyle',
    'boundaryClass',
    'labelClass',
    'colLabel',
    'colInput',
    'blur',
    'cleaveRegex',
    'is_require_flag',
  ],
  data() {
    return {
      vcleave: false,
      maxLength: false,
    }
  },
  mounted() {
    if (this.type && this.type.includes('number-length:')) {
      const length = this.type.split(':')[1];
      this.vcleave = {
        blocks: [parseInt(length)],
        numericOnly: true,
      }
    }
    if (this.type && this.type.includes('string-uppercase-length:')) {
      const length = this.type.split(':')[1];
      this.vcleave = {
        blocks: [parseInt(length)],
        uppercase: true
      }
    }
    switch (this.type) {
      case 'bigint': {
        this.vcleave = {
          blocks: [15],
          numericOnly: true,
        }
        break;
      }
      case 'int': {
        this.vcleave = {
          blocks: [8],
          numericOnly: true,
        }
        break;
      }
      case 'digits': {
        this.vcleave = {
          blocks: [191],
          numericOnly: true,
        }
        break;
      }
      case 'time': {
        this.vcleave = {
          time: true,
          timePattern: ['h', 'm']
        }
        break;
      }
      case 'email':
      case 'string': {
        this.maxLength = this.$consts.INPUT.MAX_LENGTH;
        break;
      }
      case 'url': {
        this.maxLength = this.$consts.INPUT.MAX_LENGTH_URL;
        break;
      }
      case 'number_format': {
        this.vcleave = {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
        break;
      }
      case 'ja_postal_code': {
        this.vcleave = {delimiter: '-', blocks: [3, 4], numericOnly: true}
        break;
      }
      case 'double': {
        this.vcleave = {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralDecimalScale: 2
        }
        break;
      }
      case 'price': {
        this.vcleave = {
          blocks: [15],
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numericOnly: true,
        }
        break;
      }
    }
  },
  methods: {
  }
}
</script>

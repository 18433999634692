export default {
  ROOT: 'ROOT',
  DEV_GUIDE: 'DEV_GUIDE',
  ADMIN: {
    AUTH: {
      LOGIN: 'ADMIN.LOGIN',
      LOGOUT: 'ADMIN.LOGOUT',
      FORGOT_PASSWORD: 'ADMIN.FORGOT_PASSWORD',
      RESET_PASSWORD: 'ADMIN.RESET_PASSWORD',
    },
    PROFILE: 'ADMIN.PROFILE',
    DASHBOARD: 'ADMIN.DASHBOARD',
    ALL_USERS_LIST: 'ADMIN.ALL_USERS_LIST',
    TOUR_ORDERS: 'ADMIN.TOUR_ORDERS',
    TOUR_ORDERS_CREATE: 'ADMIN.TOUR_ORDERS_CREATE',
    TOUR_ORDERS_UPDATE: 'ADMIN.TOUR_ORDERS_UPDATE',
    OFFICES: 'ADMIN.OFFICES',
    OFFICE_PLANS: 'ADMIN.OFFICE_PLANS',
    ACCOUNTS: 'ADMIN.ACCOUNTS',
    MEMBERS: 'ADMIN.MEMBERS',
    MEMBERS_UPDATE: 'ADMIN.MEMBERS_UPDATE',
    BOOKINGS: 'ADMIN.BOOKINGS',
    BOOKING_CREATE: 'ADMIN.BOOKING_CREATE',
    BOOKING_UPDATE: 'ADMIN.BOOKING_UPDATE',
    BOOKING_UPDATE_TOUR: 'ADMIN.BOOKING_UPDATE_TOUR',
    FURUSATO_PRODUCT: 'ADMIN.FURUSATO_PRODUCT',
    FURUSATO_PRODUCT_CREATE: 'ADMIN.FURUSATO_PRODUCT_CREATE',
    FURUSATO_PRODUCT_UPDATE: 'ADMIN.FURUSATO_PRODUCT_UPDATE',
    FURUSATO_COUPON: 'ADMIN.FURUSATO_COUPON',
    FURUSATO_COUPON_CREATE: 'ADMIN.FURUSATO_COUPON_CREATE',
    FURUSATO_COUPON_UPDATE: 'ADMIN.FURUSATO_COUPON_UPDATE',
    FURUSATO_COUPON_USE: 'ADMIN.FURUSATO_COUPON_USE',
  },
}
